
// window.crypto ( https://developer.mozilla.org/it/docs/Web/API/SubtleCrypto )
// Nota: in chrome disabilitato in http
// OpenCrypto.js ( https://github.com/safebash/opencrypto )
// https://github.com/sigp/ecies-parity

// encrypt con chiave pubblica MetaMask
// https://github.com/MetaMask/eth-sig-util
import { encrypt } from 'eth-sig-util'

// ECIES : vedi  Ecies.js
// https://github.com/ecies/js
import {ECIESJSkey_pair, ECIESJSdecryptB64_64, ECIESJSencryptB64} from './Ecies'

// AES e RSA
// https://www.w3.org/TR/WebCryptoAPI/#dfn-SubtleCrypto
// https://github.com/safebash/opencrypto
import OpenCrypto from './OpenCrypto'
const crypt = new OpenCrypto()

// NON PIU' USATO
// // https://github.com/sigp/ecies-parity
// const ecies = require("ecies-parity");
// https://nodejs.org/api/crypto.html
// const crypto = require("crypto");

// zlib
// https://github.com/nodeca/pako
const pako = require('pako');

// base58
// https://github.com/cryptocoinjs/bs58
const bs58 = require('bs58')



if(!window.crypto.subtle) {
    alert("Error: null window.crypto.subtle: browser (chrome) encryption not available using http, https required")
}

export const AESgenerateKey = () => {
    const length = 256;
    const options = { cipher: 'AES-CBC', usages: ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey'], isExtractable: true }
    return crypt.getSharedKey(length, options)
    // Promise : CryptoKey
}

    // CryptoKey key
export const AESkey2Base64 = (key) => {
    const type = "secret: 'raw'; private: 'pkcs8'; public: 'spki'"
    return crypt.cryptoToBase64(key, type)
    // Promise : base64String
}

    // base64String base64Key
export const AESbase64ToKey = (base64Key) => {
    const options = { name: 'AES-CBC', length: 256, usages: ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey'], isExtractable: true }
    return crypt.base64ToCrypto(base64Key, options)
    // Promise : CryptoKey
}

    // buffer keyBuf
export const AESbufferToKey = (keyBuf) => {
    const aesKeyB64 = bufferToBase64(keyBuf)
    return AESbase64ToKey(aesKeyB64)
    // Promise : CryptoKey
}

    // CryptoKey key, ArrayBuffer data
export const AESencrypt = (key,data) => {
    return crypt.encrypt(key, data)
    // Promise : base64String (nota: primi 16 caratteri sono ivB64)
}

    // CryptoKey key, base64String encryptedData (nota: primi 16 bytes sono iv)
export const AESdecrypt = (key,encryptedData) => {
    const options = { cipher: 'AES-CBC' }
    return crypt.decrypt(key, encryptedData, options)
    // Promise : ArrayBuffer 
}

    // CryptoKey key, base64String ivAndDocB64 (nota: primi 24 caratteri sono ivB64)
export const AESdecrypt1 = (key,ivAndDocB64) => {
    const ivb64 = ivAndDocB64.substring(0,24)
    const encryptedDoc = ivAndDocB64.substring(24)
    const iv = base64ToArrayBuffer(ivb64)
    const encryptedData = base64ToArrayBuffer(encryptedDoc)
    return window.crypto.subtle.decrypt(
        {
            name: "AES-CBC",
            iv: iv, //The initialization vector you used to encrypt
        },
        key, //from generateKey or importKey 
        encryptedData //ArrayBuffer of the data
    );
    // Promise : ArrayBuffer 
}

/*
export const RSAkey_pair = () => {
    const modulusLength=2048
    const hash='SHA-512'
    const paddingScheme='RSA-OAEP'
    const usages=['encrypt', 'decrypt', 'wrapKey', 'unwrapKey']
    const isExtractable=true
    return crypt.getRSAKeyPair(modulusLength, hash, paddingScheme, usages, isExtractable)
    // Promise : { CryptoKey publicKey, CryptoKey privateKey }
}

    // CryptoKey publicKey, ArrayBuffer data
export const RSAencrypt = (publicKey,data) => {
    return crypt.rsaEncrypt(publicKey, data)
    // Promise : base64String
}

    // CryptoKey privateKey, base64String encryptedData
export const RSAdecrypt = (privateKey,encryptedData) => {
    return crypt.rsaDecrypt(privateKey, encryptedData)
    // Promise : ArrayBuffer
}
*/

/*  
// INIZIO ECIES PARITY non piu' usato
export const ECIESkey_pair = () => {
    const privateKey = ECIESgeneratePrivateKey();
    const publicKey = ECIESgetPublic(privateKey);
    return {
        privateKey: privateKey,
        publicKey: publicKey
    }
    // { Buffer publicKey, Buffer privateKey }
}

export const ECIESgeneratePrivateKey = () => {
    return crypto.randomBytes(32)
    // Buffer (privateKey)
}

    // Buffer privateKeyBuf
export const ECIESgetPublic = (privateKeyBuf) => {
    return ecies.getPublic(privateKeyBuf)
    // Buffer (publicKey)
}

    // Buffer publicKeyBuf, Buffer plainBuf
export const ECIESencrypt = async (publicKeyBuf, plainBuf) => {
    return ecies.encrypt(publicKeyBuf, plainBuf)
    // Promise : Buffer
}

    // base64String publicKeyBuf64, base64String plainBuf64
export const ECIESencryptB64 = async (publicKeyBuf64, plainBuf64) => {
    const publicKeyBuf = base64ToBuffer(publicKeyBuf64)
    const plainBuf = base64ToBuffer(plainBuf64)
    const buf = await ECIESencrypt(publicKeyBuf,plainBuf)
    return bufferToBase64(buf)
    // base64String
}

    // Buffer privateKeyBuf, Buffer cipherBuf
export const ECIESdecrypt = async (privateKeyBuf, cipherBuf) => {
    return ecies.decrypt(privateKeyBuf, cipherBuf)
    // Promise : Buffer
}

    // base64String privateKeyB64, base64String cipherB64
export const ECIESdecryptB64 = async (privateKeyB64, cipherB64) => {
    const ecies_privateKey = base64ToBuffer(privateKeyB64)
    const cipherBuf = base64ToBuffer(cipherB64)
    return ECIESdecrypt(ecies_privateKey, cipherBuf)
    // Promise : Buffer
}

    // base64String privateKeyB64, base64String cipherB64
export const ECIESdecryptB64_64 = async (privateKeyB64, cipherB64) => {
    const buf = await ECIESdecryptB64(privateKeyB64, cipherB64)
    return bufferToBase64(buf)
    // base64String
}

    // base64String eciesPrivateKey64, base64String aesEncryptedKey
export const ECIESdecryptAesKeyB64 = async (eciesPrivateKey64, aesEncryptedKey) => {
    const aesKeyBuf = await ECIESdecryptB64(eciesPrivateKey64, aesEncryptedKey)
    const aesKey = await AESbufferToKey(aesKeyBuf)
    return aesKey
    // Promise : CryptoKey
}
// FINE ECIES PARITY non piu' usato
*/

export const base64ToArrayBuffer = (str) => {
    return crypt.base64ToArrayBuffer(str)
}

export const stringToArrayBuffer = (str) => {
    return crypt.stringToArrayBuffer(str)
}

export const arrayBufferToString = (buf) => {
    return crypt.arrayBufferToString(buf)
}

export const stringToBuffer = (s) => {
    return Buffer.from(s)
}

export const base64ToBuffer = (b64) => {
    return Buffer.from(b64,'base64')
}

export const bufferToString = (b) => {
    return b.toString()
}

export const bufferToBase64 = (b) => {
    return  b.toString('base64')
}

export const base64ToHex = (b64) => {
    const buffer = Buffer.from(b64,'base64')
    return buffer.toString('hex');
    // NOTA: no 0x in testa
}

    // no 0x in testa
export const hexToBuffer = (hex) => {
    return Buffer.from(hex,'hex')
}

export const base58ToHex = (b58) => {
    const buffer = bs58.decode(b58)
    return buffer.toString('hex');
    // NOTA: no 0x in testa
}

export const bufferToBase58 = (b) => {
    return  bs58.encode(b)
    // es. "16UjcYNBG9GTK4uq2f7yYEbuifqCzoLMGS"
}

    // String publicKey, String str
export const MMencrypt = (publicKey, str) => {
    let ciphertext = null
    let err = null
    try {
        ciphertext = window.web3.utils.toHex(JSON.stringify(
              encrypt(
                publicKey,
                { 'data': str },
                'x25519-xsalsa20-poly1305',
              ),
            ))
    } catch (error) {
        err = error
    }
    return {
        ciphertext: ciphertext,
        err: err
    }
    // { hexString ciphertext, String err }
}

    // hexString str, Address from
export const MMdecrypt = async (str, from) => {
    let cleartext = null
    let err = null
    try {
        from = window.web3.utils.toChecksumAddress(from)
        cleartext = await window.ethereum.request({
          method: 'eth_decrypt',
          params: [str, from]
        });
    } catch (error) {
        err = error
        /*
        if(err.code && err.code===4001) {
            alert('Permesso rifiutato');
            return;
        }
        */
    }
    return {
        cleartext: cleartext,
        err: err
    }
    // { String cleartext, String err }
}

    // Address from
export const MMgetPublicKey = async (from) => {
    let publicKey = null
    let err = null
    try {
        from = window.web3.utils.toChecksumAddress(from)
        publicKey = await window.ethereum.request({
          method: 'eth_getEncryptionPublicKey',
          params: [from]
        })
    } catch (error) {
        err = error
        /*
        if(err.code && err.code===4001) {
            alert('Permesso rifiutato');
            return;
        }
        */
    }
    return {
        publicKey: publicKey,
        err: err
    }
    // { base64String key, String err }
}

    // ArrayBuffer ab
export const ZLIBinflate = (ab) => {
    const ua = new Uint8Array(ab)
    const uade = pako.inflate(ua);
    // https://stackoverflow.com/questions/37228285/uint8array-to-arraybuffer
    // return uade.buffer;
    return uade.buffer.slice(uade.byteOffset, uade.byteLength + uade.byteOffset)
    // ArrayBuffer
}

    // string che inizia per Qm
export const hashipfsToBytes32 = (hash) => {
    const hex = base58ToHex(hash)
    if (hex.substring(0,4) !== "1220") {
        const err = "hashipfsToBytes32: hash not starting with 1220 ("+hash+" "+hex+")"
        throw err
    }
    // 0x in testa
    return '0x'+hex.substring(4)
}

    // hex con 0x in testa
export const bytes32ToHashipfs = (hex) => {
    // togliamo 0x e aggiungiamo primi 2 bytes tolti da hashipfsToBytes32
    const fullhex = "1220"+hex.substring(2)
    const bu = hexToBuffer(fullhex)
    return bufferToBase58(bu)
}

export const aescryptedkeyToBytes32Array = (cry64) => {
    let hex1,hex2,hex3,hex4,hex5
    if(!cry64) {
        hex1 = "0x0000000000000000000000000000000000000000000000000000000000000000"
        hex2 = hex1
        hex3 = hex1
        hex4 = hex1
        hex5 = hex1
    } else {
        const hex = base64ToHex(cry64)

        // erano 145 bytes con ecies.parity, ora sono 129
        if (hex.length !== 129*2) {
            const err = "aescryptedkeyToBytes32Array: aes crypted key is not 129 bytes long ("+cry64+" "+hex+")"
            throw err
        }
        hex1 = "0x"+hex.substring(0,64)
        hex2 = "0x"+hex.substring(64,128)
        hex3 = "0x"+hex.substring(128,192)
        hex4 = "0x"+hex.substring(192,256)
        // per 145 bytes, 30 zero di padding
        // per 129 bytes, 62 zero di padding
        hex5 = "0x"+hex.substring(256)+"00000000000000000000000000000000000000000000000000000000000000"
    }
    return [ hex1, hex2, hex3, hex4, hex5 ]
}

export const bytes32ArrayToAescryptedkey = (v) => {
    if(v.length !== 5) {
        const err = "bytes32ArrayToAescryptedkey: array han not 5 elements ("+v.length+")"
        throw err
    }
    const hexpadded = 
        v[0].substring(2)+
        v[1].substring(2)+
        v[2].substring(2)+
        v[3].substring(2)+
        v[4].substring(2)
    const hex = hexpadded.substring(0,129*2)
    const bu = hexToBuffer(hex)
    return bufferToBase64(bu)
}

    // hex string (NO 0x in testa) , concatenazione di bytes32Array
export const hexToAescryptedkey = (hexpadded) => {
    if(hexpadded.length !== 320) {
        const err = "hexToAescryptedkey: string is not 320 bytes length ("+hexpadded.length+")"
        throw err
    }
    const hex = hexpadded.substring(0,129*2)
    const bu = hexToBuffer(hex)
    return bufferToBase64(bu)
}


export const ECIESkey_pair = () => {
    // { Buffer publicKey, Buffer privateKey }
    return ECIESJSkey_pair()
}

    // base64String eciesPrivateKey64, base64String aesEncryptedKey
export const ECIESdecryptAesKeyB64 = async (eciesPrivateKey64, aesEncryptedKey) => {
    const aesKeyBuf = await ECIESJSdecryptB64_64(eciesPrivateKey64, aesEncryptedKey)
    const aesKey = await AESbufferToKey(aesKeyBuf)
    return aesKey
    // Promise : CryptoKey
}

    // base64String privateKeyB64, base64String cipherB64
export const ECIESdecryptB64_64 = async (privateKeyB64, cipherB64) => {
    return ECIESJSdecryptB64_64(privateKeyB64, cipherB64)
    // base64String
}
    
    // base64String publicKeyBuf64, base64String plainBuf64
export const ECIESencryptB64 = async (publicKeyBuf64, plainBuf64) => {
    return ECIESJSencryptB64(publicKeyBuf64, plainBuf64)
    // base64String
}

/*
export const provaE = async () => {
    console.log('provaE')
    const privateKeyA64 = "xMu3bQHdaOvXZ2MzOmH0G+oD9cysKth/xol+x+WaHzE="
    //const pub_key64 = "BGfuJYiAxYXmomHqSwXAXg3S+KKrVbDR+nUx1Cx98M+5iEhLlziMSZiQMmpa6OHeirkmqJI7iIrlucrfpqxahXY="

    const b64_crypted_data = "mZ5Fb4dD7XuwTGEiYb+rqw==YeTtKeWPB/UICTtTDVhBJA=="
    const aes_crypted_key = "BIplf6X3TzmrkHAnxIeUzhgsphKCYzPK5mOm3o/WVZx0CArFFNCpkQm312Gdki7LyOGeaAZix3URsX7e7svJ7yDWV3o84T16EXT9UpOcipihBbMNWSUzXwr+o+0owrBQVvWQfHWLbFTWbTBXrtLu7LSodRJyny0GcmvHzKGI4rDL"

    const aes_key = await ECIESdecryptAesKeyB64(privateKeyA64,aes_crypted_key)
    console.log(aes_key)
    const bufz = await AESdecrypt(aes_key,b64_crypted_data)
    console.log(bufz)
    const buf = ZLIBinflate(bufz)
    const ret = arrayBufferToString(buf)
    console.log(ret)

    const kp = ECIESJSkey_pair()
    console.log(kp)

    for (let i=0; i<1; i++) {
         const aes = await AESgenerateKey()
         console.log(aes)
    }
    const dapy = [{"cipher": "LaNDuYmNZef1+U22vYaGiA==Xtdo0T2JCFTyUNayxhGqCQ==", "aes_key": "BFrClGmdtEEVyTpfPLz/x3tbHvmykGlNOcZ31otSM7IURkgKueKMGDevObG52yhzvSADZ1S+16jKml+LZcYyLKcjRAgfu39rUTe6Bsu1BHegLxR2ix5WYlvDG03xmxSc01FRgrpfytNfTtuxPcEML1vNtNxP839joEIqhL8R8KTe"}]
    for (let i in dapy) {
        console.log(i)
        const cipher = dapy[i].cipher
        const aes_crypted_key = dapy[i].aes_key
        const aes_key = await ECIESdecryptAesKeyB64(privateKeyA64,aes_crypted_key)
        const bufz = await AESdecrypt(aes_key,cipher)
        const buf = ZLIBinflate(bufz)
        const ret = arrayBufferToString(buf)
        console.log(ret)
    }
}
*/
