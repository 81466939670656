
// ECIES
// https://github.com/ecies/js

import { encrypt, decrypt, PrivateKey } from 'eciesjs'

const hexToBuffer = (hex) => {
    // Buffer non vuole 0x in testa
    if (hex.substr(0,2) === "0x")
        hex = hex.substr(2)
    return Buffer.from(hex,'hex')
}

const base64ToBuffer = (b64) => {
    return Buffer.from(b64,'base64')
}

const bufferToBase64 = (b) => {
    return  b.toString('base64')
}

export const ECIESJSkey_pair = () => {
    const privateKey = new PrivateKey()
    // mah: chiave privata con 0x in testa, chiave pubblica senza.. gestiamo in hexToBuffer
    const hexPrivateKey = privateKey.toHex()
    // cosi' restituisce forma compressa su 32 bytes, noi ci aspettiamo forma non compressa su 64 bytes + 1
    //const hexPublicKey = privateKey.publicKey.toHex()
    return {
        privateKey: hexToBuffer(hexPrivateKey),
        publicKey: Buffer.from(privateKey.publicKey.uncompressed),
    }
    // { Buffer publicKey, Buffer privateKey }
}

    // base64String privateKeyB64, base64String cipherB64
export const ECIESJSdecryptB64_64 = async (privateKeyB64, cipherB64) => {
    const privateKey = base64ToBuffer(privateKeyB64)
    const cipher = base64ToBuffer(cipherB64)
    const buf = decrypt(privateKey, cipher)
    return bufferToBase64(buf)
    // base64String
}

    // base64String publicKeyBuf64, base64String plainBuf64
export const ECIESJSencryptB64 = async (publicKeyBuf64, plainBuf64) => {
    const publicKey = base64ToBuffer(publicKeyBuf64)
    const plainBuf = base64ToBuffer(plainBuf64)
    const buf = encrypt(publicKey,plainBuf)
    return bufferToBase64(buf)
    // base64String
}

