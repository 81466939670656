import { useHistory } from "react-router-dom";

export const Reload = (props) => {
    const history = useHistory()
    const path = "/"+props.match.params.path
    console.log("Reload",path)
    // funziona ma casini su history del browser, non funziona piu' back
    // con window.location.replace(path) invece mantiene stato 
    history.push(path)
    return (
            <div> <p> Loading {path}... </p> </div>
    )
}
