import React from 'react';
import MyAxios from "./MyAxios";
import { Header } from './Header';
import { Footer } from './Footer';
import { SignIn } from './auth/SignIn';
import { Redirect } from "react-router-dom";
import { useGlobalHook } from '@devhammed/use-global-hook'

export const Login = (props) => (
  <div>
    <Header empty="true" />
    <SignIn resetIdleTimeout={props.resetIdleTimeout} />
    <Footer />
  </div>
);

export function Logout() {
  console.log("Logout");
  const { setUserInfo } = useGlobalHook('userStore');
  //localStorage.removeItem("token");
  React.useEffect(() => {
    setUserInfo({});
    sessionStorage.removeItem('eciesPrivateKey');
    // MyAxios.get('userlogout', {credentials: 'omit'});
    MyAxios.get('userlogout');
  }, [setUserInfo]);
  //console.log("Logout" + localStorage.getItem("token"));
  return (<Redirect to='/login' />);
};
