import React, { useCallback } from "react";
import { useGlobalHook } from '@devhammed/use-global-hook'
import MyAxios, { check_response } from "./MyAxios";
import { MostSubmitButton, } from "./components/MostComponents";
import { MMgetPublicKey, MMencrypt, ECIESkey_pair, bufferToBase64, } from "./Crypto";
import { useTranslation } from "react-i18next";
import { now } from "./Utils";

export const BCenroll = (props) => {
  const [mmpublickey, setMmpublickey] = React.useState(false);
  const [firmaDisabled, setFirmaDisabled] = React.useState(true);
  const { t } = useTranslation(["translation"]);
  const { userInfo, setUserInfo } = useGlobalHook('userStore');
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
    setContent(text);
    setAlert1(true);
  }, [setContent,setAlert1])

  const getMmpublickey = async () => {
    props.setInsertButtonDisabled(true);
    props.setLoading(true);
    props.addEsiti(t("Richiesta della chiave pubblica a MetaMask"))
    props.addEsiti(t("Necessario premere bottone 'Fornisci' in MetaMask"))
    var from = props.account
    const ret = await MMgetPublicKey(from)
    const err = ret.err
    const mmPublicKey = ret.publicKey
    if (err) {
        if(err.code && err.code===4001) {
            props.addEsiti(t("Chiave rifiutata"));
            props.setInsertButtonDisabled(false);
            props.setLoading(false);
            return;
        }
        alert('errore: '+JSON.stringify(err));
        props.addEsiti(t("Errore")+": "+JSON.stringify(err))
        props.setInsertButtonDisabled(false);
        props.setLoading(false);
        return;
    }
    setMmpublickey(mmPublicKey)
    props.setLoading(false);
    setFirmaDisabled(false);
    props.addEsiti(t("Chiave ottenuta procedere con FIRMA RICHIESTA"));
  };

  const enrollSign = () => {

    const keypair = ECIESkey_pair()
    const privateKey64 = keypair.privateKey.toString('base64')
    const ret = MMencrypt(mmpublickey,privateKey64)
    const err = ret.err
    const privateKey64enc = ret.ciphertext
    if (err) {
        appAlert('errore: '+JSON.stringify(err))
        return
    }
    const publicKey64 = bufferToBase64(keypair.publicKey)

    var textMessage2Sign = " Questo è il messaggio da firmare per potere registrare il proprio indirizzo Ethereum. \n Utente: "+userInfo.username+" \n Data: "+now(1)+" \n Nota: non verrà effettuato nessun addebito ";
    console.log("enrollSign: ",textMessage2Sign);

    setFirmaDisabled(true);
    props.setLoading(true);
    props.addEsiti(t("Firma del messaggio"))
    props.addEsiti(t("Necessario premere bottone 'Firma' in MetaMask"))

    var msg = window.web3.utils.toHex(textMessage2Sign);

    //var accounts = await window.web3.eth.getAccounts()
    var from = props.account

    window.web3.eth.personal.sign(msg,from,(err,result) => {
        if (err) {
            // {code: 4001, message: "MetaMask Message Signature: User denied message signature."}
            if(err.code && err.code===4001) {
                props.addEsiti(t("Firma rifiutata"));
                setFirmaDisabled(false);
                props.setLoading(false);
                return;
            }
            console.error("err personal.sign",err)
            props.addEsiti(t("Errore")+": "+JSON.stringify(err))
            setFirmaDisabled(false);
            props.setLoading(false);
            return;
        }
        console.log("personal.sign ok",result);
        props.addEsiti(t("Messaggio firmato invio richiesta"));
        //alert(' account: '+from+', \n msg: '+msg+', \n PERSONAL SIGNED: ' + result.result)
        // verifica della firma:
        //      sigUtil.recoverPersonalSignature( msg sign ) -> from1
        //      from == from1 ?

        console.log("mmpublickey",mmpublickey)
        console.log("privateKey64enc",privateKey64enc)
        console.log("publicKey64",publicKey64)
        enrollPost(from,textMessage2Sign,result,mmpublickey,privateKey64enc,publicKey64);
    });
  }

  const enrollPost = (from,msg,personal_signed,metamask_publickey,ecies_privatekey,ecies_publickey) => {
    const vals = {
        from: from.toLowerCase(),
        msg: msg,
        personal_signed: personal_signed,
        metamask_publickey: metamask_publickey,
        ecies_privatekey: ecies_privatekey,
        ecies_publickey: ecies_publickey
    };
    MyAxios.post("enrollbc", vals)
      .then((response) => {
        response = check_response(response);
        // alert(JSON.stringify(response));
        //console.log(response);
        if (response.success) {
          //console.log("userInfo", response.user);
          setUserInfo(response.user)
          props.setShowGoHome(true);
          props.addEsiti(t("Operazione terminata con successo"))
        } else {
          setFirmaDisabled(false);
          console.error(response);
          props.addEsiti(t("Errore")+": "+response.error)
        }
      })
      .catch(function (error) {
        setFirmaDisabled(false);
        console.error(error);
        props.addEsiti(error.message?error.message:JSON.stringify(error));
      })
      .then(function () {
        // always executed
        props.setLoading(false);
      });
  }

  return (
    <div>
        <MostSubmitButton type="button" 
            onClick={getMmpublickey} 
            label={"1. "+t("Fornisci la tua chiave pubblica")} 
            disabled={props.disabled1}
        />
        <MostSubmitButton type="button" 
            onClick={enrollSign} 
            label={"2. "+t("Firma richiesta")} 
            disabled={!mmpublickey || firmaDisabled || props.badBCId}
        />
    </div>
)}
