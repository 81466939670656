import React, { useCallback, useState } from "react";
import { Header } from './Header';
import { Footer } from './Footer';
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useTranslation } from "react-i18next";
import { MostSubmitButton, MostButton2, WarningIcon } from "./components/MostComponents";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import MyAxios, { check_response } from "./MyAxios";

export const CertificationRequest = () => {
  const { userInfo } = useGlobalHook('userStore');
  const [disabledButs, setDisabledButs] = useState(false)
  const { t } = useTranslation(["translation", "dossier"]);
  const history = useHistory();
  const { errors, register, handleSubmit } = useForm();
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
    setContent(text);
    setAlert1(true);
  }, [setContent,setAlert1])

  const onSubmit = (vals) => {
    const form = document.getElementById("formid");
    // non gestiti con form react
    vals.N = form.N.value
    vals.note = form.note.value
    console.log("onSubmit: " + JSON.stringify(vals));
    if (vals.servizio === "series" && vals.N === "") {
        appAlert(t("Inserisci il numero di riproduzioni"))
        return
    } 
    setDisabledButs(true)
    MyAxios.post("/kit_purchase", vals)
      .then((response) => {
        response = check_response(response);
        if (response.success) {
          appAlert(t("Grazie richiesta inoltrata"))
        } else {
          console.error(response);
          appAlert(response.error);
          setDisabledButs(false)
        }
      })
      .catch(function (error) {
        console.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
        setDisabledButs(false)
      })
  }

  return (
  <div>
    <Header />
    <h1>{t("CertificationRequest")}</h1>
    <h2>{t("Listino prezzi")}</h2>
    <Container component="main" maxWidth="md" className="blackColor CertificationRequest">
    <form id="formid" onSubmit={handleSubmit(onSubmit)} noValidate >
    <table className="gray dossierDettaglioTable ethTable">
    <caption className="down">
        (*) include 1.000 riproduzioni, ulteriori 1.000 disponibili a €30.000 
    </caption>
    <tbody>
        <tr>
            <th className="radioCol">
                {errors.servizio && <span className="badValue">{t("campo obbligatorio")}</span>}
            </th>
            <th>Nome servizio Techne</th>
            <th>Prezzo unitario</th>
            <th>Sconto 2021</th>
        </tr>
        <tr>
            <td className="radioCol">
                {/*
                <input type="radio" name="servizio" value="publisher" ref={register({ required: true })} />
                */}
            </td>
            <td>publisher edition (*)</td>
            <td>€50.000</td>
            <td>50%</td>
        </tr>
        <tr>
            <td className="radioCol">
                {/*
                <input type="radio" name="servizio" value="series" ref={register({ required: true })} />
                */}
            </td>
            <td>
                series edition (per N riproduzioni) 
                <br /> numero di riproduzioni: {" "}
                <input type="number" min="2" name="N" className="nriproduzioni" />
            </td>
            <td>N * €140</td>
            <td>50%</td>
        </tr>
        <tr>
            <td className="radioCol">
                <input type="radio" name="servizio" value="single" ref={register({ required: true })} />
            </td>
            <td>single edition</td>
            <td>€200</td>
            <td>50%</td>
        </tr>
        <tr>
            <td className="radioCol">
                <input type="radio" name="servizio" value="real" ref={register({ required: true })} />
            </td>
            <td>real signature edition</td>
            <td>€300</td>
            <td>50%</td>
        </tr>
    </tbody>
    </table>
    <label>
    Note:
    <textarea name="note" rows="5" className="notetextarea"/>
    </label>
    { userInfo.email_verificata ?  (
        <div>
            <MostSubmitButton disabled={disabledButs} label={t("Richiedi il servizio selezionato")} />

        </div>
    ) : (
        <p>
            <WarningIcon /> <br />
            {t("E' necessario inserire e verificare il proprio indirizzo email per acquistare un kit")}
        </p>
    )}
    <div className="tuttoasinistra">
        <MostButton2 onClick={() => history.goBack()} label={t("dossier:Indietro")} />
    </div>
    </form>
    </Container>
    <Footer />
  </div>
    );
};
