import React, { useCallback } from "react";
import { useGlobalHook } from '@devhammed/use-global-hook'
import MyAxios, { check_response } from "./MyAxios";
import { MostSubmitButton } from "./components/MostComponents";
import { useTranslation } from "react-i18next";
import { ECIESencryptB64, ECIESdecryptB64_64 } from "./Crypto";

export const BCinvite = (props) => {
  const { t } = useTranslation(["translation"]);
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
    setContent(text);
    setAlert1(true);
  }, [setContent,setAlert1])

  const inviteFunction = () => {
    if(!sessionStorage.getItem('eciesPrivateKey'))
        props.openKeyFunction(inviteFunctionBody)
    else
        inviteFunctionBody()
  }

  const inviteFunctionBody = async () => {
    if(!sessionStorage.getItem('eciesPrivateKey')) {
        // non dovrebbe succedere
        appAlert("Chiave non aperta")
        return
    }
    props.setLoading(true);
    props.setInsertButtonDisabled(true);
    props.addEsiti(t("Calcolo delle chiavi di accesso per l'utente ospite..."))
    const dossierAesEncryptedKey64 = props.dossierInfo.json_aes_crypted_key

        const ospite = props.ospite
        const giorni = props.giorni
        const ospite_pub_key64 = props.ospite_pub_key64
        const docv = props.docv

        const eciesPrivateKey64 = sessionStorage.getItem('eciesPrivateKey');
        const dossierAesKeyBuf64 = await ECIESdecryptB64_64(eciesPrivateKey64, dossierAesEncryptedKey64)
        //console.log("dossierAesKeyBuf64 in chiaro",dossierAesKeyBuf64)
        const ospite_cryptedDossierAesKey64 = await ECIESencryptB64(ospite_pub_key64,dossierAesKeyBuf64)
        console.log("ospite_cryptedDossierAesKey64",ospite_cryptedDossierAesKey64)
        const ret = []
        for (let i in docv) {
            const id = docv[i].id
            const aes_crypted_key64 = docv[i].aes_crypted_key
            let ospite_aes_crypted_key64 = null
            if (aes_crypted_key64) {
                const aes_key64 = await ECIESdecryptB64_64(eciesPrivateKey64, aes_crypted_key64)
                //console.log("doc aes_key64 in chiaro",i,aes_key64)
                ospite_aes_crypted_key64 = await ECIESencryptB64(ospite_pub_key64,aes_key64)
            }
            console.log("doc ospite_aes_crypted_key64",i,ospite_aes_crypted_key64)
            ret.push({
                id: id,
                aes_crypted_key: ospite_aes_crypted_key64
            })
        }
        props.addEsiti(t("Operazione terminata con successo"))
        props.addEsiti(t("Registrazione nel DB delle chiavi per l'ospite..."))
        const data = {
            mode: "insert",
            ospite: ospite,
            giorni: giorni,
            ospite_cryptedDossierAesKey64: ospite_cryptedDossierAesKey64,
            dossierid: props.dossierId,
            docv: ret
        }
        console.log(data)
        MyAxios.post("/dossierinvite", data)
          .then((response) => {
            props.setLoading(false);
            response = check_response(response);
            if (response.success) {
              props.addEsiti(t("Operazione terminata con successo"))
              if(response.mail_sent)
                props.addEsiti(t("E' stata inviata una mail a")+" "+props.ospite+" "+t("per notificare che è ora disponibile la visione del dossier"))
              else
                props.addEsiti(t("NOTA: purtroppo non è stato possibile inviare una mail a")+" "+props.ospite+" "+t("per notificare che è ora disponibile la visione del dossier"))
            } else {
              console.error(response);
              props.addEsiti(t("Errore")+": "+response.error)
              props.setInsertButtonDisabled(false);
            }
          })
          .catch(function (error) {
            props.setLoading(false);
            console.error(error);
            props.addEsiti(error.message?error.message:JSON.stringify(error));
            props.setInsertButtonDisabled(false);
          })
  }

  return (
        <div>
            <MostSubmitButton
                type="button" onClick={inviteFunction}
                disabled={props.disabled}
                label={t("Invita")} />
        </div>
)}
