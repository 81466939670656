import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useGlobalHook } from '@devhammed/use-global-hook'
import MyAxios, { check_response } from "./MyAxios";
import { MostSubmitButton, } from "./components/MostComponents";
import { bcLog, bcLogParam, waitTransactionReceipt, getDossierContract } from "./BCutils";

export const BCdocs = (props) => {
  const { t } = useTranslation(["translation"]);
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
    setContent(text);
    setAlert1(true);
  }, [setContent,setAlert1])

  function onError(err) {
    props.setLoading(false)
    props.setInsertButtonDisabled(false)
    props.addEsiti("Errore: "+err)
  }

  const docsBC_onSubmit = async (vals) => {
    console.log("docsBC_onSubmit",props.dossierInfo,props.docs)
    if(!props.dossierInfo.contract_initialized) {
      appAlert("contratto non inizializzato");
      return;
    }
    let ids = 0
    const starttime = new Date().toISOString()
    for(let i in props.docs) {
        const d = props.docs[i]
        // controllo che non sia gia' scritto
        if (d.data_sync_bc && (!d.datamodifica || d.data_sync_bc > d.datamodifica)) {
            console.log("skipping",d)
            continue
        }
        ids++
    }
    if (ids === 0) {
      appAlert("nessun documento da registrare");
      return;
    }
    props.setInsertButtonDisabled(true);
    props.setLoading(true);
    try {
        const c = await getDossierContract(props.addEsiti);
        if(!c) {
            props.setInsertButtonDisabled(false);
            props.setLoading(false);
            return;
        }
        props.addEsiti(t("Recupero informazioni dei documenti del dossier"))
        const vals = {
            phase: 'new_ipfs',
            starttime: starttime,
            dossieropera_id: props.dossierInfo.id,
        }
                // xxxx fare restituire elenco doc_ids
        MyAxios.post("docs2bc", vals)
          .then((response) => {
            props.setLoading(false)
            response = check_response(response)
            if (response.success) {
                props.addEsiti(t("Modifica di NFT URI in BlockChain"))
                props.addEsiti(t("Necessario premere bottone 'Conferma' in MetaMask"))
                const from = window.web3.utils.toChecksumAddress(props.account)
                const tokenId = props.dossierInfo.token_id
                const uri = process.env.REACT_APP_IPFS + "/ipfs/" + response.ipfs_hash
                bcLog("send dossier setTokenURI(dati,datiuint) from="+from+" tokenId="+tokenId+" uri="+uri)
                c.methods.setTokenURI(
                    tokenId, uri
                ).send({
                    from: from
                },
                (err,res) => {
                    if(err) {
                        if(err.code && err.code===4001) {
                            onError("Conferma interazione contratto rifiutata")
                            return;
                        }
                        console.error("dossierContract.setTokenURI error ",err);
                        onError(JSON.stringify(err))
                    } else {
                        bcLog("got hash "+bcLogParam(res))
                        const hash = res;
                        props.addEsiti(t("Hash")+": "+hash)
                        waitTransactionReceipt(hash,(err,res) => {
                            if(err) {
                                console.error(err)
                                onError(JSON.stringify(err))
                                return;
                            } else {
                                props.addOperationOkHashToEsiti(hash)
                                props.addEsiti(t("Registrazione dell'operazione nella base dati"))
                // xxxx inviare elenco doc_ids
                                const vals = {
                                    phase: 'commit_ipfs',
                                    starttime: starttime,
                                    dossieropera_id: props.dossierInfo.id,
                                }
                                MyAxios.post("docs2bc", vals)
                                  .then((response) => {
                                    props.setLoading(false);
                                    response = check_response(response);
                                    if (response.success) {
                                      props.setShowBack(true)
                                      props.addEsiti(t("Operazione terminata con successo"))
                                    } else {
                                      console.error(response);
                                      props.addEsiti(t("Errore")+": "+response.error)
                                      props.setInsertButtonDisabled(false);
                                    }
                                  })
                                  .catch(function (error) {
                                    props.setLoading(false);
                                    console.error(error);
                                    props.addEsiti(error.message?error.message:JSON.stringify(error));
                                    props.setInsertButtonDisabled(false);
                                  })
                            }
                        })
                    }
                })
            } else {
              console.error(response)
              onError(response.error)
            }
          })
          .catch(function (error) {
            console.error(error)
            onError(error.message?error.message:JSON.stringify(error))
          })
    } catch(error) {
        console.error("dossier.multiDocumentsUpdate error",error);
        props.addEsiti(error.message?error.message:JSON.stringify(error));
        props.setLoading(false);
        props.setInsertButtonDisabled(false);
    }
  }

  return (
        <div>
            <MostSubmitButton 
                type="button" onClick={docsBC_onSubmit}
                disabled={props.disabled}
                label={t("Registra i documenti")} />
        </div>
)}
