import React, { useCallback } from "react";
import { useGlobalHook } from '@devhammed/use-global-hook'
import MyAxios, { check_response } from "./MyAxios";
import { MostSubmitButton } from "./components/MostComponents";
import { useTranslation } from "react-i18next";
import { ECIESencryptB64, ECIESdecryptB64_64, } from "./Crypto";
import { bcLog, bcLogParam, waitTransactionReceipt, getDossierContract } from "./BCutils";

export const BCsell = (props) => {
  const { t } = useTranslation(["translation"]);
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
    setContent(text);
    setAlert1(true);
  }, [setContent,setAlert1])

  const onError = (msg) => {
      props.addEsiti(msg)
      props.setLoading(false)
      props.setInsertButtonDisabled(false)
  }

  const sendKeysToBE = (mode,info,onError) => {
    console.log("sendKeysToBE",mode,info)
    if(mode === "insert")
        props.addEsiti(t("Invio chiavi al server"))
    else
        props.addEsiti(t("Memorizzazione chiavi nel DB"))
    const jdata = {
        acquirente: props.acquirente,
        mode: mode,
        dossierid: props.dossierId,
        be_dossier_key: info.aes_key,
        be_ipfs_hash: info.ipfs_hash,
        doc_keys: info.doc_keys,
    }
    MyAxios.post("/dossiersell", jdata).then((response) => {
        response = check_response(response);
        if (response.success) {
            props.addEsiti(t("ok"))
            if(mode === "insert") {
                info.ipfs_hash = response.ipfs_hash
                info.aes_key = response.aes_key
                contractSell(info,onError)
            } else {
                props.addEsiti(t("Operazione terminata con successo"))
                props.setLoading(false);
                //props.setInsertButtonDisabled(false);
            }
        } else {
            console.error(response);
            onError(response.error)
        }
    })
    .catch(function (error) {
        console.error(error);
        onError(error.message?error.message:JSON.stringify(error));
    })
  }

  const contractSell = (info,onError) => {
    console.log("contractSell",info)
    props.addEsiti(t("Modifica di NFT URI e transfer token ownership in BlockChain"))
    props.addEsiti(t("Necessario premere bottone 'Conferma' in MetaMask"))
    const from = window.web3.utils.toChecksumAddress(props.account)
    const acquirente_account = props.acquirente_bcaddress
    const to = window.web3.utils.toChecksumAddress(acquirente_account)
    const tokenId = props.dossierInfo.token_id
    const uri = process.env.REACT_APP_IPFS + "/ipfs/" + info.ipfs_hash
    bcLog("send dossier sell(tokenId,uri,from,to) from="+from+" tokenId="+tokenId+" uri="+uri+" to="+to)
    info.contract.methods.sell(
        tokenId, uri, from, to
    ).send({
        from: from
    },
    (err,res) => {
        if(err) {
            if(err.code && err.code===4001) {
                onError("Conferma interazione contratto rifiutata")
                return;
            }
            console.error("dossierContract.setTokenURI error ",err);
            onError(JSON.stringify(err))
        } else {
            bcLog("got hash "+bcLogParam(res))
            const hash = res;
            props.addEsiti(t("Hash")+": "+hash)
            waitTransactionReceipt(hash,(err,res) => {
                if(err) {
                    onError(t("Errore")+": "+JSON.stringify(err))
                } else {
                    props.addOperationOkHashToEsiti(hash)
                    bcLog("got "+bcLogParam(res))
                    sendKeysToBE("confirm",info,onError)
                }
            })
        }
    })
  }

  const sellFunction = () => {
    if(!sessionStorage.getItem('eciesPrivateKey'))
        props.openKeyFunction(sellFunctionBody)
    else
        sellFunctionBody()
  }

  const sellFunctionBody = async () => {
    if(!sessionStorage.getItem('eciesPrivateKey')) {
        // non dovrebbe succedere
        appAlert("Chiave non aperta")
        return
    }
    const contract = await getDossierContract(props.addEsiti);
    if(!contract) {
        return;
    }
    try {
            props.setLoading(true);
            props.setInsertButtonDisabled(true);
            props.addEsiti(t("Calcolo delle chiavi di accesso per l'acquirente..."))
                const acquirente_pub_key64 = props.acquirente_pub_key64
                const eciesPrivateKey64 = sessionStorage.getItem('eciesPrivateKey');
                const doc_keys = []
                for (let i in props.docv) {
                    const id = props.docv[i].id
                    let aes_crypted_key64 = props.docv[i].aes_crypted_key
                    if (aes_crypted_key64) {
                        const aes_key64 = await ECIESdecryptB64_64(eciesPrivateKey64, aes_crypted_key64)
                        aes_crypted_key64 = await ECIESencryptB64(acquirente_pub_key64,aes_key64)
                    }
                    console.log("doc acquirente_aes_crypted_key64",i,aes_crypted_key64)
                    doc_keys.push({
                        id: id, 
                        acquirente_aes_crypted_key64: aes_crypted_key64
                    })
                }
                const info = {
                    doc_keys: doc_keys,
                    contract: contract,
                }
                sendKeysToBE("insert",info,onError)
    } catch(error) {
        props.setLoading(false);
        props.setInsertButtonDisabled(false);
        console.error(error);
        appAlert("fallita sellFunctionBody")
    }
  }

  return (
        <div>
            <MostSubmitButton
                type="button" onClick={sellFunction}
                disabled={props.disabled}
                label={t("Cedi l'opera")} />
        </div>
)}

