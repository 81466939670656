import { MostSubmitButton } from "./components/MostComponents";

export const BCopenKey = (props) => {
  return (
        <div>
            <MostSubmitButton
                type="button" onClick={() => props.openKeyFunction()}
                disabled={props.disabled}
                label={props.label} />
        </div>
)}
