import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { MostSubmitButton, } from "./components/MostComponents";
//import { getDossierContract } from "./BCutils";
import { arrayBufferToString, ZLIBinflate, AESdecrypt1, ECIESdecryptAesKeyB64, } from "./Crypto";
import { prettyJson, } from "./Utils";

export const BCcheck = (props) => {
  const { t } = useTranslation(["translation"]);
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
    setContent(text);
    setAlert1(true);
  }, [setContent,setAlert1])

  const checkBC_body = async () => {
   try {
    props.setLoading(true);
    props.setInsertButtonDisabled(true);
    console.log("tokenURI_content",props.dossierInfo.tokenURI_content)
    const json = props.dossierInfo.tokenURI_content
    const eciesPrivateKey64 = sessionStorage.getItem('eciesPrivateKey')
    // se utente invitato bisogna usare la sua chiave, non quella del proprietario
    // non usiamo chiave del json ma quella che arriva dal db che e' stata
    // eventualmente sostituita con quella giusta dell'utente
    const aes_crypted_key = props.dossierInfo.json_aes_crypted_key
    const aesKey = await ECIESdecryptAesKeyB64(eciesPrivateKey64, aes_crypted_key)
    //console.log("aesKey",aesKey)
    const ab = await AESdecrypt1(aesKey,json.techne_data)
    //console.log("ab",ab)
    const ab1 = ZLIBinflate(ab)
    //console.log("ab1",ab1)
    const jsonString = arrayBufferToString(ab1)
    console.log("jsonString",jsonString)
    const obj = JSON.parse(jsonString)
    props.addEsiti("Campo techne_data dei metadati:")
    props.addEsiti(prettyJson(obj,1))

    // decrypt parte di codice dna
    const dnaAesKey = await ECIESdecryptAesKeyB64(eciesPrivateKey64, obj.cod_dna_aes_key)
    //console.log("dnaAesKey",dnaAesKey)
    const dab = await AESdecrypt1(dnaAesKey,obj.cod_dna)
    //console.log("dab",dab)
    const dab1 = ZLIBinflate(dab)
    //console.log("dab1",dab1)
    const codDna = arrayBufferToString(dab1)
    props.addEsiti("Campo cod_dna:")
    props.addEsiti(codDna)
    props.setLoading(false)
   } catch(error) {
        props.setLoading(false)
        console.error(error);
        appAlert("fallita checkBC_body")
   }
  }

  const checkBC_onSubmit = () => {
    if(!sessionStorage.getItem('eciesPrivateKey'))
        props.openKeyFunction(checkBC_body)
    else
        checkBC_body()
  }

  return (
        <div>
            <MostSubmitButton 
                type="button" onClick={checkBC_onSubmit}
                disabled={props.disabled}
                label={t("Effettua la decodifica")} />
        </div>
)}
