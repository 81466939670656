import axios from "axios";

const ax = axios.create({
  baseURL: process.env.REACT_APP_BE,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    'Content-Type': 'application/json;charset=UTF-8'
  },
});
ax.interceptors.request.use(request => {
  //console.log('Request', JSON.stringify(request, null, 2))
  console.log('Request', request.method, request.url, request.data)
  return request
})
ax.interceptors.response.use(response => {
  //console.log('Response:', JSON.stringify(response, null, 2))
  console.log('Response:', response.config.url, response.status, response.data)
  //console.log('Response:', response.config.url, response.status, response.data, JSON.stringify(response, null, 2))
  return response
})
export default ax;

export function check_response(response) {
    // analizzo la risposta standard MOST:
    // 1. success true/false
    // 2. payload
    // ritorna un json con dentro il payload ed il booleano di success
    // in caso di errore, al json aggiungo l'errore (c'e' gia')
    //console.log("CHECK_RESPONSE",response);
    if (response.status !== 200) {
        //let error = "CHECK_RESPONSE status " + response.status;
        let error = "status " + response.status;
        console.log(error);
        response.data = { success: false, error: error }
    } else if (response.data.success) {
        // ok
        /*
        if ('payload' in response.data) {
          for (const [key, value] of Object.entries(response.data.payload)) {
            response.data[key] = value;
        }}
        */
    } else {
        if ((response.data.error).search("APIsession_required") !== -1 ) {
            console.error("session timeout: ", response.data.error);
            // operazioni fatto da /logout
                //localStorage.removeItem("token");
                //setUserInfo({});
            // va bene non mettere nello stack /logout, no history
            window.location.replace('/logout');
        }
    }
    return (response.data)
}
