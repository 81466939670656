import React from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { SignUp } from './auth/SignUp';

export const Register = (props) => {
  return (
  <div>
    <Header empty="true" />
    <SignUp resetIdleTimeout={props.resetIdleTimeout} />
    <Footer />
  </div>
  );
};
