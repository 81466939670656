import MyAxios from "./MyAxios";

export const bcLog = (s) => {
    const d = new Date()
    const t = d.getUTCHours()+":"+d.getUTCMinutes()+":"+d.getUTCSeconds()
    console.log(t+" BLOCKCHAIN "+s)
}

export const bcLogParam = (obj) => {
    //return JSON.stringify(obj,null,2)
    return JSON.stringify(obj)
}

const getRevertCause = async(txHash) => {
    try {
        const tx = await window.web3.eth.getTransaction(txHash)
        var result = await window.web3.eth.call(tx, tx.blockNumber)
        result = result.startsWith('0x') ? result : `0x${result}`
        if (result && result.substr(138)) {
            const reason = window.web3.utils.toAscii(result.substr(138))
            console.log('Revert reason:', reason)
            return reason
        } else {
            console.log('Cannot get reason - No return value')
        }
    } catch (e) {
        console.log('error',e)
        try {
            const msg = e.message
            const gr = msg.indexOf('{')
            const js = msg.substr(gr)
            console.log(js)
            const st = JSON.parse(js)
            return st.message
        } catch (e) {
            console.log('error',e)
        }
    }
}

export const waitTransactionReceipt = (hash,handler,nolog) => {
    if(!nolog)
        console.log("waitTransactionReceipt",hash);
    window.web3.eth.getTransactionReceipt(hash, async (err,res) => {
        if(err) {
            console.error("getTransactionReceipt error ",err);
            if (handler)
                handler(err,null);
        } else {
            // viene restituito null se non ancora minata transazione
            if(!res) {
                setTimeout(() => {
                    waitTransactionReceipt(hash,handler,1);
                }, 1000);
                return;
            }
            bcLog("got receipt "+bcLogParam(res))
            if(!res.status) {
                console.error("getTransactionReceipt status error");
                const cause = await getRevertCause(hash)
                if (handler)
                    handler("receipt status error ("+cause+")",null);
            } else {
                if (handler)
                    handler(null,res);
            }
        }
    });
  }

export const getDossierContractStruct = async (error_handler) => {
    console.log("getDossierContractStruct")
    const ret = await getContract("Dossier721",error_handler)
    if(!ret)
        return null
    if(!ret.contractAddress) {
        const error = "getDossierContractStruct: no contractAddress"
        console.error(error)
        if(error_handler)
            error_handler(error)
        return null
    }
    return ret
  }

export const getDossierContract = async (error_handler) => {
    const ret = await getDossierContractStruct(error_handler)
    if(!ret)
        return null
    const c = await new window.web3.eth.Contract(ret.abi,ret.contractAddress)
    return c
  }

export const getContract = (c,error_handler) => {
    console.log("getContract");
    let url = 'get_contract/' + c;
    return MyAxios.get(url)
      .then(response => {
        // non e' solita risposta con success
        //console.log(response);
        if (response.status !== 200 ) {
            console.error(response);
            if(error_handler)
                error_handler(JSON.stringify(response))
            return;
        }
        const abi = JSON.parse(response.data.abi);

        return {
            abi: abi,
            contractAddress: response.data.contractAddress,
            bytecode: response.data.bytecode
        };
      })
      .catch(function (error) {
        console.error(error);
        if(error_handler)
            error_handler( error.message?error.message:JSON.stringify(error) )
      });
  }

// https://stackoverflow.com/questions/72161117/how-to-read-events-log-from-transaction-receipt-with-web3-js
// https://web3js.readthedocs.io/en/v1.2.11/web3-eth-abi.html#decodelog
export const getEvent = (contractStruct,receipt,eventName) => {
    // cerchiamo definizione dell'evento che ci interessa (eventName)
    let inputs = null
    for(const el of contractStruct.abi) {
        if(el.name === eventName && el.type === "event") {
            inputs = el.inputs
            break
        }
    }
    if(!inputs)
        return null
    // topics non bisogna passare topics[0]
    const topics = []
    for(let i=1; i<receipt.logs[0].topics.length; i++) {
        topics.push(receipt.logs[0].topics[i])
    }
    const ev = window.web3.eth.abi.decodeLog(inputs,receipt.logsBloom,topics)
    return ev
  }
