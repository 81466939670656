import React, { useState } from "react";
import { Header } from '../Header';
import { Footer } from '../Footer';
import { useForm } from "react-hook-form";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useTranslation } from "react-i18next";
import Container from '@material-ui/core/Container';
import MyAxios from "../MyAxios";
import { MostSubmitButton, MostTextField, } from "../components/MostComponents";
import useStyles from "../components/useStyles";

export function ChangePasswd() {
const { t } = useTranslation();
const { register, handleSubmit } = useForm();
const classes = useStyles();
const { setAlert1, setContent } = useGlobalHook('alertStore');
const [disabledButs, setDisabledButs] = useState(false)
function appAlert(text) {
  setContent(text);
  setAlert1(true);
}
const { setAlert2, setContent2, setOnClose2 } = useGlobalHook('alert2Store');
function appAlert2(text,closeHandler) {
  setContent2(text)
  setOnClose2(() => x => {
    closeHandler()
  });
  setAlert2(true)
}

const onSubmit = vals => {
    // cosi' logghiamo password
    //console.log("onSubmit: " + JSON.stringify(vals));
    if (vals.oldpassword === vals.newpassword) {
        appAlert("La nuova password non può coincidere con la vecchia")
        return
    }
    if (vals.newpassword !== vals.newpassword2) {
        appAlert("Le nuove password non coincidono")
        return
    }
    let data= {
        oldpassword: vals.oldpassword,
        newpassword: vals.newpassword
    }

    setDisabledButs(true)
    MyAxios.post("userchangepassword", data)
    .then((response) => {
        //alert(JSON.stringify(response));
        //console.log(response);
        if (response.status !== 200 ) {
            console.error(response);
            appAlert(response.message?response.message:JSON.stringify(response));
            setDisabledButs(false)
            return;
        }
        const res = response.data;
        if (res.success) {
            appAlert2(t("Password modificata. Riconnettiti all'applicazione"),() => {
                window.location.replace('/logout')
            })
        } else {
            console.error("Error: "+res.error);
            appAlert(res.error);
            setDisabledButs(false)
        }
    })
    .catch(function (error) {
        // handle error
        console.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
        setDisabledButs(false)
    })
}

  return (
  <div>
    <Header />
    <Container component="main" maxWidth="xs">
      <h1>{t("Cambio Password")}</h1>
      <div className={classes.paper}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form} >
          <MostTextField name="oldpassword" required={true} label="Vecchia Password" autoComplete="current-password" type="password" register={register} />
          <MostTextField name="newpassword" required={true} label="Nuova Password" type="password" register={register} />
          <MostTextField name="newpassword2" required={true} label="Conferma Nuova Password" type="password" register={register} />
          <MostSubmitButton disabled={disabledButs} className="makeStyles-submit-4" label={t('Conferma')}/>
        </form>
      </div>
    </Container>
    <Footer />
  </div>
  );
}
