import React, { useState } from "react"
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
//import Avatar from "@material-ui/core/Avatar";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
//import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { useGlobalHook } from '@devhammed/use-global-hook'
import MyAxios from "../MyAxios";
import useStyles from "../components/useStyles";
import {
  MostSubmitButton,
  MostTextField,
} from "../components/MostComponents";
import logo from '../DT-alpha.png';

export function SignUp(props) {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const [disabledButs, setDisabledButs] = useState(false)
  const { setUserInfo } = useGlobalHook('userStore');
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  function appAlert(text) {
    setContent(text);
    setAlert1(true);
  }
  const infoUrl = process.env.REACT_APP_BE+"static/info.html"

  const onSubmit = (vals) => {
    // cosi' logghiamo password
    //console.log("onSubmit: " + JSON.stringify(vals));
    //alert("onSubmit: " + JSON.stringify(vals));
    if(vals.password !== vals.password2) {
        appAlert(t("SignIn.passwords differ"));
        return;
    }

    let data = {
      username: vals.username,
      password: vals.password,
    };
    setDisabledButs(true)
    MyAxios.post("newguest", data)
      .then((response) => {
        //alert(JSON.stringify(response));
        //console.log(response);
        if (response.status !== 200) {
          console.error(response);
          appAlert(response.message?response.message:JSON.stringify(response));
          setDisabledButs(false)
          return;
        }
        const res = response.data;
        if (res.success) {
          props.resetIdleTimeout()
          // console.log("Token: " + response.data.token);
          //localStorage.setItem("token", "tokenOK");
          setUserInfo(res.user);
          history.push("/identity");
        } else {
          console.log("Error: newguest: " + res.error);
          appAlert(t("Accesso fallito")+": " + res.error);
          setDisabledButs(false)
        }
      })
      .catch(function (error) {
        // handle error
        console.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
        setDisabledButs(false)
      })
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        {/*
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        */}
        <img src={logo} className="xxxxApp-logo" alt="logo" />
        <Typography component="h1" variant="h5">
          <Trans i18nKey="techneTitle" />
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form} >
          <MostTextField name="username" required={true} label={t("SignIn.Username")} type="text" autoFocus={true} register={register} />
          <MostTextField name="password" required={true} label="Password" type="password" register={register} />
          <MostTextField name="password2" required={true} label={t("SignIn.password2")} type="password" register={register} />
          <MostSubmitButton disabled={disabledButs} className="makeStyles-submit-4" label={t("SignIn.Register")} />
          <Link to="/login" variant="body2">
            {t("SignIn.Already have an account? Sign in")}
          </Link>
          <br />
          <br />
          <a href={infoUrl} rel="noreferrer">
            {t('Clicca qui per help e informazioni')}
          </a>
        </form>
      </div>
    </Container>
  );
}
